/* @font-face declarations */
@font-face {
    font-family: 'Hitchcut';
    src: url('assets/fonts/Hitchcut-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    }


@font-face {
    font-family: 'Exo2';
    src: url('assets/fonts/Exo2-VariableFont_wght.ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    }





@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('assets/fonts/PlusJakartaSansRomanRegular.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    }
    
    /* Define text sizes and weights directly */
    .text-size0 {
    font-size: 1rem;
    font-weight: 300;
    }
    
    .text-size1 {
    font-size: 1rem;
    font-weight: 400;
    }
    
    .text-size2 {
    font-size: 1.1rem;
    font-weight: 400;
    }
    
    .text-size3 {
    font-size: 1.2rem;
    font-weight: 400;
    }
    
    .text-size4 {
    font-size: 1.3rem;
    font-weight: 400;
    }
    
    .text-size5 {
    font-size: 1.5rem;
    font-weight: 400;
    }
    
    .text-thin {
    font-weight: 300;
    }
    
    .text-smbold {
    font-weight: 500;
    }
    
    .text-mdbold {
    font-weight: 600;
    }
    
    .text-lgbold {
    font-weight: 700;
    }
    
    .text-white { color: white; }  
    
    .text-underline {
        text-decoration: underline;
    }
    
    /* Responsive Text */
    /* .text-responsive {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    }
    
    .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    } */
    
    
    
    
    
    /* @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsSemiBold.ttf"); font-family : "Poppins"; font-weight : 600;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterMedium.ttf"); font-family : "Inter"; font-weight : 500;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterSemiBold.ttf"); font-family : "Inter"; font-weight : 600;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/EBGaramondRomanMedium.ttf"); font-family : "EB Garamond"; font-weight : 500;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterRegular.ttf"); font-family : "Inter"; font-weight : 400;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PlusJakartaSansRomanMedium.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 500;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/EBGaramondRomanRegular.ttf"); font-family : "EB Garamond"; font-weight : 400;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RobotoRomanRegular.ttf"); font-family : "Roboto"; font-weight : 400;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/FiraCodeRegular.ttf"); font-family : "Fira Code"; font-weight : 400;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RobotoItalicRegular.ttf"); font-family : "Roboto"; font-weight : 400;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RobotoRegular.ttf"); font-family : "Roboto"; font-weight : 400;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RobotoMedium.ttf"); font-family : "Roboto"; font-weight : 500;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/NotoSansSemiBold.ttf"); font-family : "Noto Sans"; font-weight : 600;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/NotoSansRegular.ttf"); font-family : "Noto Sans"; font-weight : 400;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterBold.ttf"); font-family : "Inter"; font-weight : 700;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PlusJakartaSansExtraBold.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 800;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PlusJakartaSansItalicRegular.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 400;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PlusJakartaSansRomanRegular.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 400;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PlusJakartaSansRomanSemiBold.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 600;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PlusJakartaSansRomanBold.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 700;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PlusJakartaSansItalicBold.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 700;  }
    @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PlusJakartaSansItalicLight.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 300;  }
    */