:root { 
    --brightred:#720300;
    --darkred:#2F0F0E;
    --darkteal:#003530;
    --brightteal:#00897B;
    --darkgreen:#122B1D;
    --brightgreen:#00662D;
    --mediumbrown:#664634;
    --darkpurple:#2E1A4F;
    --brightpurple:#300080;
    --brightbrown:#79635A;
    
    --gray_800_f2:#664634f2; 
    --gray_50_01:#fafafa; 
    --gray_50_02:#f9f9f9; 
    --deep_orange_A100:#f5ad72; 
    --deep_purple_400:#8f4cbf; 
    --black_900_91:#00000091; 
    --black_900_3f:#0000003f; 
    --gray_900_0c:#1018280c; 
    --cyan_400_19:#17c3ce19; 
    --black_900_01:#0c0c0c; 
    --black_900_00:#00091100; 
    --teal_500:#0cb37f; 
    --teal_700:#00915d; 
    --teal_900:#00352f; 
    --blue_gray_900_02:#303139; 
    --blue_gray_900_01:#343540; 
    --gray_900_b2_01:#16181eb2; 
    --blue_gray_900:#343541; 
    --gray_600:#706c6c; 
    --gray_400:#c1c1c1; 
    --teal_900_f2:#00352ff2; 
    --blue_gray_100:#d9d9d9; 
    --amber_500:#fbbc05; 
    --gray_400_3f:#d4c0c03f; 
    --gray_800:#664634; 
    --teal_500_0f:#0cb37f0f; 
    --amber_300:#ffd147; 
    --gray_200:#eeeeee; 
    --red_300_0f:#fb75740f; 
    --gray_400_01:#c5c5d1; 
    --indigo_A700:#0038ff; 
    --gray_400_02:#bbbbbb; 
    --gray_400_03:#c4c4c4; 
    --gray_100_01:#f5f5f5; 
    --gray_400_04:#c4c9ce; 
    --black_900_19:#00000019; 
    --white_A700:#ffffff; 
    --cyan_400:#16c3ce; 
    --red_300_19:#fb757419; 
    --cyan_A400:#0feffd; 
    --blue_gray_900_19:#34354119; 
    --blue_gray_50:#f1f1f1; 
    --red_700:#cf302f; 
    --light_green_A400:#51ff00; 
    --light_blue_200:#82dbf7; 
    --deep_purple_500:#5536da; 
    --red_300:#fb7574; 
    --indigo_A200:#4a6ee0; 
    --blue_gray_900_4c:#3f2c264c; 
    --gray_50:#fbfcfc; 
    --teal_200:#8dcdb8; 
    --green_400:#4aa380; 
    --teal_600:#00897b; 
    --black_900:#000000; 
    --yellow_900:#e26f20; 
    --blue_gray_800:#444654; 
    --black_900_28:#00000028; 
    --green_400_01:#4ac97e; 
    --blue_gray_50_01:#eaecf0; 
    --gray_900_02:#2f0f0e; 
    --black_900_a0:#000000a0; 
    --blue_gray_50_02:#ececf1; 
    --gray_900_03:#392d0b; 
    --gray_700:#79635a; 
    --gray_900_04:#0c1132; 
    --blue_gray_200:#b5bac1; 
    --gray_500:#9a9b9f; 
    --gray_900_05:#131313; 
    --blue_gray_900_05:#193037; 
    --amber_600:#ffb900; 
    --blue_gray_900_04:#333333; 
    --gray_900:#202123; 
    --gray_900_01:#122b1d; 
    --blue_gray_900_03:#3f2c26; 
    --teal_500_01:#0fa47f; 
    --gray_900_f2:#202123f2; 
    --blue_gray_900_7f:#3435417f; 
    --gray_900_06:#16181e; 
    --gray_900_07:#232428; 
    --gray_100:#f2f3f5; 
    --gray_900_b2:#202123b2; 
    --blue_gray_50_03:#edeef1; 
    }